const countries = [
    {
      country: "Afghanistan",
      country_code: 93,
      iso_code: "AF"
    },
    {
      country: "Albania",
      country_code: 355,
      iso_code: "AL"
    },
    {
      country: "Algeria",
      country_code: 213,
      iso_code: "DZ"
    },
    {
      country: "American Samoa",
      country_code: "1-684",
      iso_code: "AS"
    },
    {
      country: "Andorra",
      country_code: 376,
      iso_code: "AD"
    },
    {
      country: "Angola",
      country_code: 244,
      iso_code: "AO"
    },
    {
      country: "Anguilla",
      country_code: "1-264",
      iso_code: "AI"
    },
    {
      country: "Antarctica",
      country_code: 672,
      iso_code: "AQ"
    },
    {
      country: "Antigua and Barbuda",
      country_code: "1-268",
      iso_code: "AG"
    },
    {
      country: "Argentina",
      country_code: 54,
      iso_code: "AR"
    },
    {
      country: "Armenia",
      country_code: 374,
      iso_code: "AM"
    },
    {
      country: "Aruba",
      country_code: 297,
      iso_code: "AW"
    },
    {
      country: "Australia",
      country_code: 61,
      iso_code: "AU"
    },
    {
      country: "Austria",
      country_code: 43,
      iso_code: "AT"
    },
    {
      country: "Azerbaijan",
      country_code: 994,
      iso_code: "AZ"
    },
    {
      country: "Bahamas",
      country_code: "1-242",
      iso_code: "BS"
    },
    {
      country: "Bahrain",
      country_code: 973,
      iso_code: "BH"
    },
    {
      country: "Bangladesh",
      country_code: 880,
      iso_code: "BD"
    },
    {
      country: "Barbados",
      country_code: "1-246",
      iso_code: "BB"
    },
    {
      country: "Belarus",
      country_code: 375,
      iso_code: "BY"
    },
    {
      country: "Belgium",
      country_code: 32,
      iso_code: "BE"
    },
    {
      country: "Belize",
      country_code: 501,
      iso_code: "BZ"
    },
    {
      country: "Benin",
      country_code: 229,
      iso_code: "BJ"
    },
    {
      country: "Bermuda",
      country_code: "1-441",
      iso_code: "BM"
    },
    {
      country: "Bhutan",
      country_code: 975,
      iso_code: "BT"
    },
    {
      country: "Bolivia",
      country_code: 591,
      iso_code: "BO"
    },
    {
      country: "Bosnia and Herzegovina",
      country_code: 387,
      iso_code: "BA"
    },
    {
      country: "Botswana",
      country_code: 267,
      iso_code: "BW"
    },
    {
      country: "Brazil",
      country_code: 55,
      iso_code: "BR"
    },
    {
      country: "British Indian Ocean Territory",
      country_code: 246,
      iso_code: "IO"
    },
    {
      country: "British Virgin Islands",
      country_code: "1-284",
      iso_code: "VG"
    },
    {
      country: "Brunei",
      country_code: 673,
      iso_code: "BN"
    },
    {
      country: "Bulgaria",
      country_code: 359,
      iso_code: "BG"
    },
    {
      country: "Burkina Faso",
      country_code: 226,
      iso_code: "BF"
    },
    {
      country: "Burundi",
      country_code: 257,
      iso_code: "BI"
    },
    {
      country: "Cambodia",
      country_code: 855,
      iso_code: "KH"
    },
    {
      country: "Cameroon",
      country_code: 237,
      iso_code: "CM"
    },
    {
      country: "Canada",
      country_code: 1,
      iso_code: "CA"
    },
    {
      country: "Cape Verde",
      country_code: 238,
      iso_code: "CV"
    },
    {
      country: "Cayman Islands",
      country_code: "1-345",
      iso_code: "KY"
    },
    {
      country: "Central African Republic",
      country_code: 236,
      iso_code: "CF"
    },
    {
      country: "Chad",
      country_code: 235,
      iso_code: "TD"
    },
    {
      country: "Chile",
      country_code: 56,
      iso_code: "CL"
    },
    {
      country: "China",
      country_code: 86,
      iso_code: "CN"
    },
    {
      country: "Christmas Island",
      country_code: 61,
      iso_code: "CX"
    },
    {
      country: "Cocos Islands",
      country_code: 61,
      iso_code: "CC"
    },
    {
      country: "Colombia",
      country_code: 57,
      iso_code: "CO"
    },
    {
      country: "Comoros",
      country_code: 269,
      iso_code: "KM"
    },
    {
      country: "Cook Islands",
      country_code: 682,
      iso_code: "CK"
    },
    {
      country: "Costa Rica",
      country_code: 506,
      iso_code: "CR"
    },
    {
      country: "Croatia",
      country_code: 385,
      iso_code: "HR"
    },
    {
      country: "Cuba",
      country_code: 53,
      iso_code: "CU"
    },
    {
      country: "Curacao",
      country_code: 599,
      iso_code: "CW"
    },
    {
      country: "Cyprus",
      country_code: 357,
      iso_code: "CY"
    },
    {
      country: "Czech Republic",
      country_code: 420,
      iso_code: "CZ"
    },
    {
      country: "Democratic Republic of the Congo",
      country_code: 243,
      iso_code: "CD"
    },
    {
      country: "Denmark",
      country_code: 45,
      iso_code: "DK"
    },
    {
      country: "Djibouti",
      country_code: 253,
      iso_code: "DJ"
    },
    {
      country: "Dominica",
      country_code: "1-767",
      iso_code: "DM"
    },
    {
      country: "Dominican Republic",
      country_code: "1-809, 1-829, 1-849",
      iso_code: "DO"
    },
    {
      country: "East Timor",
      country_code: 670,
      iso_code: "TL"
    },
    {
      country: "Ecuador",
      country_code: 593,
      iso_code: "EC"
    },
    {
      country: "Egypt",
      country_code: 20,
      iso_code: "EG"
    },
    {
      country: "El Salvador",
      country_code: 503,
      iso_code: "SV"
    },
    {
      country: "Equatorial Guinea",
      country_code: 240,
      iso_code: "GQ"
    },
    {
      country: "Eritrea",
      country_code: 291,
      iso_code: "ER"
    },
    {
      country: "Estonia",
      country_code: 372,
      iso_code: "EE"
    },
    {
      country: "Ethiopia",
      country_code: 251,
      iso_code: "ET"
    },
    {
      country: "Falkland Islands",
      country_code: 500,
      iso_code: "FK"
    },
    {
      country: "Faroe Islands",
      country_code: 298,
      iso_code: "FO"
    },
    {
      country: "Fiji",
      country_code: 679,
      iso_code: "FJ"
    },
    {
      country: "Finland",
      country_code: 358,
      iso_code: "FI"
    },
    {
      country: "France",
      country_code: 33,
      iso_code: "FR"
    },
    {
      country: "French Polynesia",
      country_code: 689,
      iso_code: "PF"
    },
    {
      country: "Gabon",
      country_code: 241,
      iso_code: "GA"
    },
    {
      country: "Gambia",
      country_code: 220,
      iso_code: "GM"
    },
    {
      country: "Georgia",
      country_code: 995,
      iso_code: "GE"
    },
    {
      country: "Germany",
      country_code: 49,
      iso_code: "DE"
    },
    {
      country: "Ghana",
      country_code: 233,
      iso_code: "GH"
    },
    {
      country: "Gibraltar",
      country_code: 350,
      iso_code: "GI"
    },
    {
      country: "Greece",
      country_code: 30,
      iso_code: "GR"
    },
    {
      country: "Greenland",
      country_code: 299,
      iso_code: "GL"
    },
    {
      country: "Grenada",
      country_code: "1-473",
      iso_code: "GD"
    },
    {
      country: "Guam",
      country_code: "1-671",
      iso_code: "GU"
    },
    {
      country: "Guatemala",
      country_code: 502,
      iso_code: "GT"
    },
    {
      country: "Guernsey",
      country_code: "44-1481",
      iso_code: "GG"
    },
    {
      country: "Guinea",
      country_code: 224,
      iso_code: "GN"
    },
    {
      country: "Guinea-Bissau",
      country_code: 245,
      iso_code: "GW"
    },
    {
      country: "Guyana",
      country_code: 592,
      iso_code: "GY"
    },
    {
      country: "Haiti",
      country_code: 509,
      iso_code: "HT"
    },
    {
      country: "Honduras",
      country_code: 504,
      iso_code: "HN"
    },
    {
      country: "Hong Kong",
      country_code: 852,
      iso_code: "HK"
    },
    {
      country: "Hungary",
      country_code: 36,
      iso_code: "HU"
    },
    {
      country: "Iceland",
      country_code: 354,
      iso_code: "IS"
    },
    {
      country: "India",
      country_code: 91,
      iso_code: "IN"
    },
    {
      country: "Indonesia",
      country_code: 62,
      iso_code: "ID"
    },
    {
      country: "Iran",
      country_code: 98,
      iso_code: "IR"
    },
    {
      country: "Iraq",
      country_code: 964,
      iso_code: "IQ"
    },
    {
      country: "Ireland",
      country_code: 353,
      iso_code: "IE"
    },
    {
      country: "Isle of Man",
      country_code: "44-1624",
      iso_code: "IM"
    },
    {
      country: "Israel",
      country_code: 972,
      iso_code: "IL"
    },
    {
      country: "Italy",
      country_code: 39,
      iso_code: "IT"
    },
    {
      country: "Ivory Coast",
      country_code: 225,
      iso_code: "CI"
    },
    {
      country: "Jamaica",
      country_code: "1-876",
      iso_code: "JM"
    },
    {
      country: "Japan",
      country_code: 81,
      iso_code: "JP"
    },
    {
      country: "Jersey",
      country_code: "44-1534",
      iso_code: "JE"
    },
    {
      country: "Jordan",
      country_code: 962,
      iso_code: "JO"
    },
    {
      country: "Kazakhstan",
      country_code: 7,
      iso_code: "KZ"
    },
    {
      country: "Kenya",
      country_code: 254,
      iso_code: "KE"
    },
    {
      country: "Kiribati",
      country_code: 686,
      iso_code: "KI"
    },
    {
      country: "Kosovo",
      country_code: 383,
      iso_code: "XK"
    },
    {
      country: "Kuwait",
      country_code: 965,
      iso_code: "KW"
    },
    {
      country: "Kyrgyzstan",
      country_code: 996,
      iso_code: "KG"
    },
    {
      country: "Laos",
      country_code: 856,
      iso_code: "LA"
    },
    {
      country: "Latvia",
      country_code: 371,
      iso_code: "LV"
    },
    {
      country: "Lebanon",
      country_code: 961,
      iso_code: "LB"
    },
    {
      country: "Lesotho",
      country_code: 266,
      iso_code: "LS"
    },
    {
      country: "Liberia",
      country_code: 231,
      iso_code: "LR"
    },
    {
      country: "Libya",
      country_code: 218,
      iso_code: "LY"
    },
    {
      country: "Liechtenstein",
      country_code: 423,
      iso_code: "LI"
    },
    {
      country: "Lithuania",
      country_code: 370,
      iso_code: "LT"
    },
    {
      country: "Luxembourg",
      country_code: 352,
      iso_code: "LU"
    },
    {
      country: "Macau",
      country_code: 853,
      iso_code: "MO"
    },
    {
      country: "Macedonia",
      country_code: 389,
      iso_code: "MK"
    },
    {
      country: "Madagascar",
      country_code: 261,
      iso_code: "MG"
    },
    {
      country: "Malawi",
      country_code: 265,
      iso_code: "MW"
    },
    {
      country: "Malaysia",
      country_code: 60,
      iso_code: "MY"
    },
    {
      country: "Maldives",
      country_code: 960,
      iso_code: "MV"
    },
    {
      country: "Mali",
      country_code: 223,
      iso_code: "ML"
    },
    {
      country: "Malta",
      country_code: 356,
      iso_code: "MT"
    },
    {
      country: "Marshall Islands",
      country_code: 692,
      iso_code: "MH"
    },
    {
      country: "Mauritania",
      country_code: 222,
      iso_code: "MR"
    },
    {
      country: "Mauritius",
      country_code: 230,
      iso_code: "MU"
    },
    {
      country: "Mayotte",
      country_code: 262,
      iso_code: "YT"
    },
    {
      country: "Mexico",
      country_code: 52,
      iso_code: "MX"
    },
    {
      country: "Micronesia",
      country_code: 691,
      iso_code: "FM"
    },
    {
      country: "Moldova",
      country_code: 373,
      iso_code: "MD"
    },
    {
      country: "Monaco",
      country_code: 377,
      iso_code: "MC"
    },
    {
      country: "Mongolia",
      country_code: 976,
      iso_code: "MN"
    },
    {
      country: "Montenegro",
      country_code: 382,
      iso_code: "ME"
    },
    {
      country: "Montserrat",
      country_code: "1-664",
      iso_code: "MS"
    },
    {
      country: "Morocco",
      country_code: 212,
      iso_code: "MA"
    },
    {
      country: "Mozambique",
      country_code: 258,
      iso_code: "MZ"
    },
    {
      country: "Myanmar",
      country_code: 95,
      iso_code: "MM"
    },
    {
      country: "Namibia",
      country_code: 264,
      iso_code: "NA"
    },
    {
      country: "Nauru",
      country_code: 674,
      iso_code: "NR"
    },
    {
      country: "Nepal",
      country_code: 977,
      iso_code: "NP"
    },
    {
      country: "Netherlands",
      country_code: 31,
      iso_code: "NL"
    },
    {
      country: "Netherlands Antilles",
      country_code: 599,
      iso_code: "AN"
    },
    {
      country: "New Caledonia",
      country_code: 687,
      iso_code: "NC"
    },
    {
      country: "New Zealand",
      country_code: 64,
      iso_code: "NZ"
    },
    {
      country: "Nicaragua",
      country_code: 505,
      iso_code: "NI"
    },
    {
      country: "Niger",
      country_code: 227,
      iso_code: "NE"
    },
    {
      country: "Nigeria",
      country_code: 234,
      iso_code: "NG"
    },
    {
      country: "Niue",
      country_code: 683,
      iso_code: "NU"
    },
    {
      country: "North Korea",
      country_code: 850,
      iso_code: "KP"
    },
    {
      country: "Northern Mariana Islands",
      country_code: "1-670",
      iso_code: "MP"
    },
    {
      country: "Norway",
      country_code: 47,
      iso_code: "NO"
    },
    {
      country: "Oman",
      country_code: 968,
      iso_code: "OM"
    },
    {
      country: "Pakistan",
      country_code: 92,
      iso_code: "PK"
    },
    {
      country: "Palau",
      country_code: 680,
      iso_code: "PW"
    },
    {
      country: "Palestine",
      country_code: 970,
      iso_code: "PS"
    },
    {
      country: "Panama",
      country_code: 507,
      iso_code: "PA"
    },
    {
      country: "Papua New Guinea",
      country_code: 675,
      iso_code: "PG"
    },
    {
      country: "Paraguay",
      country_code: 595,
      iso_code: "PY"
    },
    {
      country: "Peru",
      country_code: 51,
      iso_code: "PE"
    },
    {
      country: "Philippines",
      country_code: 63,
      iso_code: "PH"
    },
    {
      country: "Pitcairn",
      country_code: 64,
      iso_code: "PN"
    },
    {
      country: "Poland",
      country_code: 48,
      iso_code: "PL"
    },
    {
      country: "Portugal",
      country_code: 351,
      iso_code: "PT"
    },
    {
      country: "Puerto Rico",
      country_code: "1-787, 1-939",
      iso_code: "PR"
    },
    {
      country: "Qatar",
      country_code: 974,
      iso_code: "QA"
    },
    {
      country: "Republic of the Congo",
      country_code: 242,
      iso_code: "CG"
    },
    {
      country: "Reunion",
      country_code: 262,
      iso_code: "RE"
    },
    {
      country: "Romania",
      country_code: 40,
      iso_code: "RO"
    },
    {
      country: "Russia",
      country_code: 7,
      iso_code: "RU"
    },
    {
      country: "Rwanda",
      country_code: 250,
      iso_code: "RW"
    },
    {
      country: "Saint Barthelemy",
      country_code: 590,
      iso_code: "BL"
    },
    {
      country: "Saint Helena",
      country_code: 290,
      iso_code: "SH"
    },
    {
      country: "Saint Kitts and Nevis",
      country_code: "1-869",
      iso_code: "KN"
    },
    {
      country: "Saint Lucia",
      country_code: "1-758",
      iso_code: "LC"
    },
    {
      country: "Saint Martin",
      country_code: 590,
      iso_code: "MF"
    },
    {
      country: "Saint Pierre and Miquelon",
      country_code: 508,
      iso_code: "PM"
    },
    {
      country: "Saint Vincent and the Grenadines",
      country_code: "1-784",
      iso_code: "VC"
    },
    {
      country: "Samoa",
      country_code: 685,
      iso_code: "WS"
    },
    {
      country: "San Marino",
      country_code: 378,
      iso_code: "SM"
    },
    {
      country: "Sao Tome and Principe",
      country_code: 239,
      iso_code: "ST"
    },
    {
      country: "Saudi Arabia",
      country_code: 966,
      iso_code: "SA"
    },
    {
      country: "Senegal",
      country_code: 221,
      iso_code: "SN"
    },
    {
      country: "Serbia",
      country_code: 381,
      iso_code: "RS"
    },
    {
      country: "Seychelles",
      country_code: 248,
      iso_code: "SC"
    },
    {
      country: "Sierra Leone",
      country_code: 232,
      iso_code: "SL"
    },
    {
      country: "Singapore",
      country_code: 65,
      iso_code: "SG"
    },
    {
      country: "Sint Maarten",
      country_code: "1-721",
      iso_code: "SX"
    },
    {
      country: "Slovakia",
      country_code: 421,
      iso_code: "SK"
    },
    {
      country: "Slovenia",
      country_code: 386,
      iso_code: "SI"
    },
    {
      country: "Solomon Islands",
      country_code: 677,
      iso_code: "SB"
    },
    {
      country: "Somalia",
      country_code: 252,
      iso_code: "SO"
    },
    {
      country: "South Africa",
      country_code: 27,
      iso_code: "ZA"
    },
    {
      country: "South Korea",
      country_code: 82,
      iso_code: "KR"
    },
    {
      country: "South Sudan",
      country_code: 211,
      iso_code: "SS"
    },
    {
      country: "Spain",
      country_code: 34,
      iso_code: "ES"
    },
    {
      country: "Sri Lanka",
      country_code: 94,
      iso_code: "LK"
    },
    {
      country: "Sudan",
      country_code: 249,
      iso_code: "SD"
    },
    {
      country: "Suriname",
      country_code: 597,
      iso_code: "SR"
    },
    {
      country: "Svalbard and Jan Mayen",
      country_code: 47,
      iso_code: "SJ"
    },
    {
      country: "Swaziland",
      country_code: 268,
      iso_code: "SZ"
    },
    {
      country: "Sweden",
      country_code: 46,
      iso_code: "SE"
    },
    {
      country: "Switzerland",
      country_code: 41,
      iso_code: "CH"
    },
    {
      country: "Syria",
      country_code: 963,
      iso_code: "SY"
    },
    {
      country: "Taiwan",
      country_code: 886,
      iso_code: "TW"
    },
    {
      country: "Tajikistan",
      country_code: 992,
      iso_code: "TJ"
    },
    {
      country: "Tanzania",
      country_code: 255,
      iso_code: "TZ"
    },
    {
      country: "Thailand",
      country_code: 66,
      iso_code: "TH"
    },
    {
      country: "Togo",
      country_code: 228,
      iso_code: "TG"
    },
    {
      country: "Tokelau",
      country_code: 690,
      iso_code: "TK"
    },
    {
      country: "Tonga",
      country_code: 676,
      iso_code: "TO"
    },
    {
      country: "Trinidad and Tobago",
      country_code: "1-868",
      iso_code: "TT"
    },
    {
      country: "Tunisia",
      country_code: 216,
      iso_code: "TN"
    },
    {
      country: "Turkey",
      country_code: 90,
      iso_code: "TR"
    },
    {
      country: "Turkmenistan",
      country_code: 993,
      iso_code: "TM"
    },
    {
      country: "Turks and Caicos Islands",
      country_code: "1-649",
      iso_code: "TC"
    },
    {
      country: "Tuvalu",
      country_code: 688,
      iso_code: "TV"
    },
    {
      country: "U.S. Virgin Islands",
      country_code: "1-340",
      iso_code: "VI"
    },
    {
      country: "Uganda",
      country_code: 256,
      iso_code: "UG"
    },
    {
      country: "Ukraine",
      country_code: 380,
      iso_code: "UA"
    },
    {
      country: "United Arab Emirates",
      country_code: 971,
      iso_code: "AE"
    },
    {
      country: "United Kingdom",
      country_code: 44,
      iso_code: "GB"
    },
    {
      country: "United States",
      country_code: 1,
      iso_code: "US"
    },
    {
      country: "Uruguay",
      country_code: 598,
      iso_code: "UY"
    },
    {
      country: "Uzbekistan",
      country_code: 998,
      iso_code: "UZ"
    },
    {
      country: "Vanuatu",
      country_code: 678,
      iso_code: "VU"
    },
    {
      country: "Vatican",
      country_code: 379,
      iso_code: "VA"
    },
    {
      country: "Venezuela",
      country_code: 58,
      iso_code: "VE"
    },
    {
      country: "Vietnam",
      country_code: 84,
      iso_code: "VN"
    },
    {
      country: "Wallis and Futuna",
      country_code: 681,
      iso_code: "WF"
    },
    {
      country: "Western Sahara",
      country_code: 212,
      iso_code: "EH"
    },
    {
      country: "Yemen",
      country_code: 967,
      iso_code: "YE"
    },
    {
      country: "Zambia",
      country_code: 260,
      iso_code: "ZM"
    },
    {
      country: "Zimbabwe",
      country_code: 263,
      iso_code: "ZW"
    }
]

export default countries;