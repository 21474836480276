<template>
  <div class="flex h-screen">
    <div
      class="md:w-1/3 hidden bg-blue-800 md:flex items-center justify-center"
    >
      <div>
        <center>
          <img
            src="@/assets/image/logo/logo-asli-white.png"
            width="165"
            class="mb-3"
          />
          <h1 class="text-white font-bold text-2xl mb-1">
            Jasa Pengiriman Luar Negeri
          </h1>
          <h3 class="text-white font-medium text-md">www.jaskipin.co.id</h3>
        </center>
      </div>
    </div>
    <div class="w-full md:w-2/3 bg-white md:p-12 p-10 flex items-center">
      <div class="w-full mx-auto md:max-w-md">
        <img
          src="@/assets/image/logo/logo-asli-white.png"
          width="145"
          class="mb-8 mx-auto md:hidden"
        />
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <div
            v-show="errorStatus"
            class="
              text-white
              px-6
              py-4
              border-0
              rounded
              relative
              mb-4
              bg-red-600
            "
          >
            <span class="text-xl inline-block mr-3 align-middle">
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <span class="inline-block align-middle mr-8">
              <b class="capitalize">Gagal!</b> Email atau Password anda salah!
            </span>
            <button
              @click="errorStatus = false"
              class="
                absolute
                bg-transparent
                text-2xl
                font-semibold
                leading-none
                right-0
                top-0
                mt-4
                mr-6
                outline-none
                focus:outline-none
              "
            >
              <span>×</span>
            </button>
          </div>
        </transition>
        <h1 class="mb-1 text-2xl font-extrabold text-left text-gray-800">
          Login
        </h1>
        <h4 class="mb-7 text-sm font-medium text-left text-gray-800">
          Login Agen Jaskipin
        </h4>
        <form class="pb-1 space-y-4" @submit="handleLogin">
          <label class="block">
            <span class="block mb-1 text-sm font-medium text-left text-gray-800"
              >Email</span
            >
            <input
              v-model="email"
              class="
                bg-gray-100
                text-sm
                w-full
                px-4
                py-2.5
                focus:outline-none
                rounded-md
              "
              type="email"
              placeholder="Ex. james@bond.com"
              inputmode="email"
              required
            />
          </label>
          <label class="block">
            <span class="block mb-1 text-sm font-medium text-left text-gray-800"
              >Kata Sandi/Password</span
            >
            <input
              v-model="password"
              class="
                bg-gray-100
                text-sm
                w-full
                px-4
                py-2.5
                focus:outline-none
                rounded-md
              "
              type="password"
              placeholder="••••••••"
              required
            />
          </label>
          <button
            type="submit"
            class="
              bg-blue-800
              text-sm
              w-full
              px-4
              py-2.5
              hover:bg-blue-900
              text-white
              font-medium
              rounded-md
              focus:outline-none
              transition
              flex
              items-center
              justify-center
            "
          >
            <svg
              v-show="loading"
              class="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            LOGIN
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { api_url } from "@/config/api";

export default {
  data() {
    return {
      /* Data Form */
      email: "",
      password: "",
      /* Error Message */
      errorStatus: false,
      /* Extra */
      loading: false,
    };
  },
  created() {
    let token = JSON.parse(
      window.sessionStorage.getItem("token-auth-jaskipin-agen")
    );
    if (token) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async handleLogin(e) {
      this.loading = true;

      e.preventDefault();

      let formData = {
        email: this.email,
        password: this.password,
      };

      /* Login via API */
      try {
        const response = await axios.post(
          `${api_url}/api/v1/auth/login`,
          formData
        );
        console.log(response);

        /* Store Token to LocalStorage */
        window.sessionStorage.setItem(
          "token-auth-jaskipin-agen",
          JSON.stringify(response.data)
        );

        /* Fetch Data Profile */
        try {
          const getProfile = await axios.get(`${api_url}/api/v1/auth/profile`, {
            headers: {
              token: response.data.token,
            },
          });
          console.log(getProfile);

          /* Store Data Profile to LocalStorage */
          window.sessionStorage.setItem(
            "data-profile-jaskipin-agen",
            JSON.stringify(getProfile.data)
          );

          /* Check User */
          // if (
          //   getProfile.data.type_user !== "Master Agen" &&
          //   getProfile.data.type_user !== "Agen"
          // ) {
          //   this.autoLogout();
          // }

          /* Refresh Page */
          window.location.reload();

          /* Set Loading State */
          this.loading = false;
        } catch (error) {
          console.error(error);
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.errorStatus = true;

        this.loading = false;
      }
    },
    autoLogout() {
      // Destroy Data Auth in LocalStorage
      window.sessionStorage.removeItem("token-auth-jaskipin-agen");
      window.sessionStorage.removeItem("data-profile-jaskipin-agen");
      // Redirect to Login Page
      window.location.reload();
    },
  },
};
</script>

<style scoped></style>
