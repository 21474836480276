import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import InternationalTransaction from "../views/Transaksi/International.vue";
import DomesticTransaction from "../views/Transaksi/Domestic.vue";
import ResiDomestic from "../views/Transaksi/Print/ResiDomestic.vue";
/* Report Pages */
import Resi from "../views/Laporan/Resi.vue";
import Nota from "../views/Laporan/Nota.vue";
import Proforma from "../views/Laporan/Proforma.vue";
import SuratJalan from "../views/Laporan/SuratJalan.vue";
import AuthorityLetter from "../views/Laporan/AuthorityLetter.vue";
import Tabitha from "../views/Laporan/Tabitha.vue";
import Citylink from "../views/Laporan/Citylink.vue";
/* Print Pages */
import LaporanResiPrint from "../views/Laporan/Print/resi-print.vue";
import LaporanNotaPrint from "../views/Laporan/Print/nota-print.vue";
import LaporanProformaPrint from "../views/Laporan/Print/proforma-print.vue";
import LaporanSuratJalanPrint from "../views/Laporan/Print/surat-jalan-print.vue";
import LaporanAuthorityLetterPrint from "../views/Laporan/Print/authority-letter-print.vue";
import LaporanTabithaPrint from "../views/Laporan/Print/tabitha-print.vue";
import LaporanCitylinkPrint from "../views/Laporan/Print/citylink-print.vue";
/* Report Agen Pages */
import NotaAgen from "../views/LaporanAgen/NotaAgen.vue";
import ResiAgen from "../views/LaporanAgen/ResiAgen.vue";
import ProformaAgen from "../views/LaporanAgen/ProformaAgen.vue";
import AuthorityLetterAgen from "../views/LaporanAgen/AuthorityLetterAgen.vue";
/* Print Agen Pages */
import LaporanNotaAgenPrint from "../views/LaporanAgen/Print/nota-agen-print.vue";
import LaporanResiAgenPrint from "../views/LaporanAgen/Print/resi-agen-print.vue";
import LaporanProformaAgenPrint from "../views/LaporanAgen/Print/proforma-agen-print.vue";
import LaporanAuthorityLetterAgenPrint from "../views/LaporanAgen/Print/authority-letter-agen-print.vue";
/* Finance Pages */
import FinanceTagihanAgen from "../views/Finance/TagihanAgen.vue";
/* Tracking */
import Tracking from "../views/Tracking.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaksi-internasional",
    name: "InternationalTransaction",
    component: InternationalTransaction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transaksi-domestik",
    name: "DomesticTransaction",
    component: DomesticTransaction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/resi-domestik/:id",
    name: "ResiDomestic",
    component: ResiDomestic,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-center/resi",
    name: "Resi",
    component: Resi,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-center/nota",
    name: "Nota",
    component: Nota,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-center/proforma",
    name: "Proforma",
    component: Proforma,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-center/surat-jalan",
    name: "SuratJalan",
    component: SuratJalan,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-center/authority-letter",
    name: "AuthorityLetter",
    component: AuthorityLetter,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-center/tabitha",
    name: "Tabitha",
    component: Tabitha,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-center/citylink",
    name: "Citylink",
    component: Citylink,
    meta: {
      requiresAuth: true,
    },
  },
  /*------------------*/
  /* Print Page Route */
  /*------------------*/
  {
    path: "/print/resi/:id",
    name: "print-resi",
    component: LaporanResiPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/nota/:id",
    name: "print-nota",
    component: LaporanNotaPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/proforma/:id",
    name: "print-proforma",
    component: LaporanProformaPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/surat-jalan",
    name: "print-surat-jalan",
    component: LaporanSuratJalanPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/authority-letter/:id",
    name: "print-authority-letter",
    component: LaporanAuthorityLetterPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/tabitha/:id",
    name: "print-tabitha",
    component: LaporanTabithaPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/citylink/:id",
    name: "print-citylink",
    component: LaporanCitylinkPrint,
    meta: {
      requiresAuth: true,
    },
  },
  /*------------------------*/
  /* Report Agen Page Route */
  /*------------------------*/
  {
    path: "/report-agen/nota-agen",
    name: "NotaAgen",
    component: NotaAgen,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-agen/resi-agen",
    name: "ResiAgen",
    component: ResiAgen,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-agen/proforma-agen",
    name: "ProformaAgen",
    component: ProformaAgen,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-agen/authority-letter-agen",
    name: "AuthorityLetterAgen",
    component: AuthorityLetterAgen,
    meta: {
      requiresAuth: true,
    },
  },
  /*-----------------------*/
  /* Print Agen Page Route */
  /*-----------------------*/
  {
    path: "/print/nota-agen/:id",
    name: "print-nota-agen",
    component: LaporanNotaAgenPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/resi-agen/:id",
    name: "print-resi-agen",
    component: LaporanResiAgenPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/proforma-agen/:id",
    name: "print-proforma-agen",
    component: LaporanProformaAgenPrint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/print/authority-letter-agen/:id",
    name: "print-authority-letter-agen",
    component: LaporanAuthorityLetterAgenPrint,
    meta: {
      requiresAuth: true,
    },
  },
  /*--------------------*/
  /* Finance Page Route */
  /*--------------------*/
  {
    path: "/finance/tagihan-agen",
    name: "finance-tagihan-agen",
    component: FinanceTagihanAgen,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/*==============================================*/
/* Simple Authentication by Zakiy Fadhil Muhsin */
/* version: 0.0.1                               */
/*==============================================*/
let token = JSON.parse(
  window.sessionStorage.getItem("token-auth-jaskipin-agen")
);

/* Navigation Guard for Auth Access */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});
/*===============================================*/

export default router;
