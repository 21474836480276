<template>
  <section class="min-h-screen bg-gray-50">
    <nav
      class="fixed z-20 top-0 left-0 h-full pb-10 overflow-x-hidden overflow-y-auto transition origin-left transform bg-blue-800 w-64 md:translate-x-0"
      :class="{ '-translate-x-full': !sidebar, 'translate-x-0': sidebar }"
      @click="sidebar = false"
    >
      <a href="/" class="flex items-end justify-center px-4 py-5">
        <img
          src="@/assets/image/logo/logo-asli-white.png"
          alt="Jaskipin Logo"
          class="w-28"
        />
        <span class="text-gray-100 font-bold font-sans text-md">Mitra</span>
      </a>
      <nav
        class="text-sm font-medium text-white px-4 py-4"
        aria-label="Main Navigation"
      >
        <a
          @click="$router.push('/dashboard')"
          :class="
            $route.path.includes('dashboard')
              ? 'text-gray-200 bg-blue-900 rounded-md'
              : ''
          "
          class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
            />
          </svg>
          <span>Dashboard</span>
        </a>
        <a
          @click="$router.push('/transaksi-internasional')"
          :class="
            $route.path.includes('transaksi-internasional')
              ? 'text-gray-200 bg-blue-900 rounded-md'
              : ''
          "
          class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Transaksi International</span>
        </a>
        <a
          @click="$router.push('/transaksi-domestik')"
          :class="
            $route.path.includes('transaksi-domestik')
              ? 'text-gray-200 bg-blue-900 rounded-md'
              : ''
          "
          class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
          href="#"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            />
            <path
              d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z"
            />
          </svg>
          <span>Transaksi Domestik</span>
        </a>
        <a
          @click="$router.push('/finance/tagihan-agen')"
          :class="
            $route.path.includes('tagihan-agen')
              ? 'text-gray-200 bg-blue-900 rounded-md'
              : ''
          "
          class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Tagihan Agen</span>
        </a>
        <!-- <div v-if="profileData.type_user === 'Agen'">
                    <div :class="$route.path.includes('report-center') ? 'text-gray-200 bg-blue-900 rounded-md' : ''" class="flex items-center justify-between px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200" role="button" @click="sidebar = true; openMenuReport = !openMenuReport">
                        <div class="flex items-center">
                            <svg class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>
                            <span>Laporan</span>
                        </div>
                        <svg :class="{ 'rotate-90': openMenuReport }" class="flex-shrink-0 w-4 h-4 ml-2 transition transform"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="mb-4" v-show="openMenuReport">
                        <a @click="$router.push('/report-center/resi')" :class="$route.path.includes('resi') ? 'font-bold text-white rounded-md' : 'text-gray-200'" class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-300" href="#">Resi</a>
                        <a @click="$router.push('/report-center/nota')" :class="$route.path.includes('nota') ? 'font-bold text-white rounded-md' : 'text-gray-200'" class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-300" href="#">Nota & Label Pengiriman</a>
                        <a @click="$router.push('/report-center/proforma')" :class="$route.path.includes('proforma') ? 'font-bold text-white rounded-md' : 'text-gray-200'" class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-300" href="#">Proforma Invoice</a>
                        <a @click="$router.push('/report-center/surat-jalan')" :class="$route.path.includes('surat-jalan') ? 'font-bold text-white rounded-md' : 'text-gray-200'" class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-300" href="#">Surat Jalan</a>
                        <a @click="$router.push('/report-center/authority-letter')" :class="$route.path.includes('authority-letter') ? 'font-bold text-white rounded-md' : 'text-gray-200'" class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-300" href="#">Authority Letter</a>
                        <a @click="$router.push('/report-center/tabitha')" :class="$route.path.includes('tabitha') ? 'font-bold text-white rounded-md' : 'text-gray-200'" class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-300" href="#">Tabitha</a>
                        <a @click="$router.push('/report-center/citylink')" :class="$route.path.includes('citylink') ? 'font-bold text-white rounded-md' : 'text-gray-200'" class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-300" href="#">Citylink</a>
                    </div>
                </div> -->
        <div v-if="profileData.type_user === 'Agen'">
          <div
            :class="
              $route.path.includes('report-agen')
                ? 'text-gray-200 bg-blue-900 rounded-md'
                : ''
            "
            class="flex items-center justify-between px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
            role="button"
            @click="
              sidebar = true;
              openMenuReportAgen = !openMenuReportAgen;
            "
          >
            <div class="flex items-center">
              <svg
                class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                <path
                  fill-rule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Laporan Agen</span>
            </div>
            <svg
              :class="{ 'rotate-90': openMenuReportAgen }"
              class="flex-shrink-0 w-4 h-4 ml-2 transition transform"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="mb-4" v-show="openMenuReportAgen">
            <a
              @click="$router.push('/report-agen/nota-agen')"
              :class="
                $route.path.includes('nota-agen')
                  ? 'font-bold text-white rounded-md'
                  : 'text-gray-200'
              "
              class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-200"
              href="#"
              >Nota (Agen)</a
            >
            <a
              @click="$router.push('/report-agen/resi-agen')"
              :class="
                $route.path.includes('resi-agen')
                  ? 'font-bold text-white rounded-md'
                  : 'text-gray-200'
              "
              class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-200"
              href="#"
              >Resi (Agen)</a
            >
            <a
              @click="$router.push('/report-agen/proforma-agen')"
              :class="
                $route.path.includes('proforma-agen')
                  ? 'font-bold text-white rounded-md'
                  : 'text-gray-200'
              "
              class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-200"
              href="#"
              >Proforma Invoice (Agen)</a
            >
            <a
              @click="$router.push('/report-agen/authority-letter-agen')"
              :class="
                $route.path.includes('authority-letter-agen')
                  ? 'font-bold text-white rounded-md'
                  : 'text-gray-200'
              "
              class="flex items-center py-2 pl-12 pr-4 transition cursor-pointer hover:bg-blue-900 hover:text-gray-200"
              href="#"
              >Authority Letter (Agen)</a
            >
          </div>
        </div>
        <a
          @click="$router.push('/tracking')"
          :class="
            $route.path.includes('tracking')
              ? 'text-gray-200 bg-blue-900 rounded-md'
              : ''
          "
          class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          <span>Tracking</span>
        </a>
        <a
          @click="myAccountModal = true"
          class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>

          <span>Akun Saya</span>
        </a>
        <a
          @click="logout"
          class="flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M18,2H6C5.447,2,5,2.447,5,3v9l5-4v3h6v2h-6v3l-5-4v9c0,0.553,0.447,1,1,1h12c0.553,0,1-0.447,1-1V3 C19,2.447,18.553,2,18,2z"
            ></path>
          </svg>
          <span>Logout</span>
        </a>
        <a
          @click="sidebar = false"
          class="lg:hidden flex items-center px-4 py-3 transition cursor-pointer group hover:bg-blue-900 hover:text-gray-200"
        >
          <svg
            class="flex-shrink-0 w-5 h-5 mr-2 text-white transition group-hover:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <!-- <span>Tutup</span> -->
        </a>
      </nav>
    </nav>
    <div class="ml-0 transition md:ml-64">
      <header class="flex items-center justify-between w-full px-4 h-14">
        <button
          class="block btn btn-light-secondary md:hidden"
          @click="sidebar = true"
        >
          <span class="sr-only">Menu</span>
          <svg
            class="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="flex items-center ml-auto">
          <div class="px-4 py-2 flex items-center justify-between">
            <h2 class="font-medium text-green-600 text-xs mr-3">
              Saldo: Rp{{
                profileData.saldo ? formatPrice(profileData.saldo) : 0
              }}
            </h2>
            <button
              @click="topupSaldoDialog = !topupSaldoDialog"
              class="bg-green-600 hover:bg-green-600 focus:outline-none px-2 py-1 text-white font-semibold rounded-md text-xs"
            >
              Topup
            </button>
          </div>
          <a
            @click="profileOpen = !profileOpen"
            class="text-gray-500 cursor-pointer"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <div
              v-show="profileOpen"
              class="origin-top-right absolute right-0 mt-28 z-40 mr-4 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <a
                @click="myAccountModal = true"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                >Akun Saya</a
              >
              <a
                @click="logout"
                class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                >Logout</a
              >
            </div>
          </transition>
        </div>
      </header>
      <div class="p-4">
        <!-- Add content here, remove div below -->
        <slot></slot>
      </div>
    </div>
    <!-- Sidebar Backdrop -->
    <div
      class="fixed inset-0 z-10 w-screen h-screen bg-black bg-opacity-25 md:hidden transition"
      v-show="sidebar"
    ></div>
    <!-- Import My Account Component -->
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="myAccountModal"
        class="fixed z-10 inset-0 transition-opacity"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
    </transition>
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="opacity-100 translate-y-0 sm:scale-100"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <myAccount
        v-show="myAccountModal"
        :userId="profileData.id"
        @close="myAccountModal = false"
      ></myAccount>
    </transition>
    <!-- Topup Saldo with Detail Modal Component -->
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="topupSaldoDialog"
        class="fixed inset-0 transition-opacity z-20"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
    </transition>
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="opacity-100 translate-y-0 sm:scale-100"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <detailModal
        v-show="topupSaldoDialog"
        title="Topup Saldo"
        @close="topupSaldoDialog = false"
      >
        <div class="p-6">
          <div class="mb-6">
            <h1>Silahkan Hubungi CS/Admin untuk Isi Saldo</h1>
            <div class="pt-4">
              <a
                href="https://wa.me/62811244344"
                target="_blank"
                class="bg-blue-600 hover:bg-blue-700 px-3 py-2 font-semibold text-white text-sm rounded-md focus:outline-none"
                >Hubungi CS/Admin</a
              >
            </div>
          </div>
        </div>
      </detailModal>
    </transition>
  </section>
</template>

<script>
import myAccount from "./my-account";
import detailModal from "@/components/datatables/detail_modal";
import axios from "axios";
import { api_url } from "@/config/api";

export default {
  components: { myAccount, detailModal },
  data() {
    return {
      sidebar: false,
      openMenuReport: false,
      openMenuReportAgen: false,
      profileOpen: false,
      myAccountModal: false,
      topupSaldoDialog: false,
    };
  },
  computed: {
    profileData() {
      let profile = JSON.parse(
        window.sessionStorage.getItem("data-profile-jaskipin-agen")
      );
      if (profile) {
        return profile;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.refreshDataProfile();
  },
  methods: {
    logout() {
      // Destroy Data Auth in LocalStorage
      window.sessionStorage.removeItem("token-auth-jaskipin-agen");
      window.sessionStorage.removeItem("data-profile-jaskipin-agen");
      // Redirect to Login Page
      this.$router.push("/");
    },
    formatPrice(number) {
      var rupiah = "";
      var angkarev = number
        .toString()
        .split("")
        .reverse()
        .join("");
      for (var i = 0; i < angkarev.length; i++)
        if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
      return rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("");
    },
    async refreshDataProfile() {
      /* Fetch Data Profile */
      try {
        const getProfile = await axios.get(`${api_url}/api/v1/auth/profile`, {
          headers: JSON.parse(
            window.sessionStorage.getItem("token-auth-jaskipin")
          ),
        });
        console.log(getProfile);

        /* Remove Recent Data Profile */
        window.sessionStorage.removeItem("data-profile-jaskipin");

        /* Store Data Profile to LocalStorage */
        window.sessionStorage.setItem(
          "data-profile-jaskipin",
          JSON.stringify(getProfile.data)
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped></style>
