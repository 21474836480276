<template>
  <div>
    <SidebarNavigation>
      <div class="px-4 py-4">
        <div class="bg-blue-400 px-10 py-8 rounded-lg bg-header-image h-64">
          <h1 class="text-white font-semibold text-2xl mb-5">
            Hello {{ profileData.fullname }}
          </h1>
          <h1 class="text-white font-sans text-xl">
            Selamat Datang di Aplikasi Mitra<br />Jaskipin Express
          </h1>
          <button
            @click="$router.push('/transaksi-internasional')"
            class="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-5 py-2 rounded-md mt-9 focus:outline-none transition transform hover:scale-110"
          >
            Mulai Transaksi
          </button>
        </div>
      </div>
    </SidebarNavigation>
  </div>
</template>

<script>
import SidebarNavigation from "@/components/SidebarNavigation";

export default {
  components: { SidebarNavigation },
  data() {
    return {
      //
    };
  },
  computed: {
    profileData() {
      let profile = JSON.parse(
        window.sessionStorage.getItem("data-profile-jaskipin-agen")
      );
      if (profile) {
        return profile;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.bg-header-image {
  background-image: url("../assets/image/characters/agen.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom -100px right;
}
</style>
