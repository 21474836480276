<template>
    <div class="px-10 py-10 max-w-4xl">
        <!-- Header -->
        <div class="flex items-center mb-5">
            <img src="@/assets/logo-opsional2.png" class="w-20" />
            <div class="ml-5">
                <h1 class="font-semibold text-2xl font-sans">JASKIPIN EXPRESS INDONESIA</h1>
                <h4>www.jaskipin.co.id</h4>
            </div>
        </div>
        <h1 class="font-semibold text-center text-lg mb-3">Carrier: {{ selectedItem[0].courier }}</h1>
        <h5 class="text-xs font-semibold text-right mr-1 mb-1">{{ formatDate(new Date()) }}</h5>
        <hr class="border-gray-800 mb-5" style="border: 1px solid black" />
        <h1 class="font-semibold text-center text-lg mb-3">Manifest Export</h1>
        <table class="w-full">
            <thead>
                <tr>
                    <td class="border border-gray-800 px-2 text-center font-semibold text-sm">
                        No
                    </td>
                    <td v-for="(item, i) in selectedColumns" :key="i" class="border border-gray-800 px-2 text-center font-semibold text-sm">
                        {{ item.label }}
                    </td>
                    <!-- <td class="border border-gray-800 px-2 text-center font-semibold text-sm">
                        Tujuan
                    </td>
                    <td class="border border-gray-800 px-2 text-center font-semibold text-sm">
                        AWB
                    </td>
                    <td class="border border-gray-800 px-2 text-center font-semibold text-sm">
                        Berat
                    </td>
                    <td class="border border-gray-800 px-2 text-center font-semibold text-sm">
                        Cabang
                    </td> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in selectedItem" :key="index">
                    <td class="border border-gray-800 px-2 text-sm">{{ index + 1 }}</td>
                    <td v-for="(column, i) in selectedColumns" :key="i" class="border border-gray-800 px-2 text-sm">
                        {{ column.field == 'createdAt' ? formatDate(item[column.field]) : item[column.field] }}
                    </td>
                    <!-- <td class="border border-gray-800 px-2 text-sm">{{ item.recipient_destination }}</td>
                    <td class="border border-gray-800 px-2 text-sm">{{ item.awb_no }}</td>
                    <td class="border border-gray-800 px-2 text-sm">{{ item.weight }}</td>
                    <td class="border border-gray-800 px-2 text-sm capitalize">{{ profileData.branch }}</td> -->
                </tr>
            </tbody>
        </table>
        <h4 class="font-medium text-xs mt-3">*Mohon di cek dan informasikan jika ada ketidaksesuaian data.</h4>
    </div>
</template>

<script>
export default {
    computed: {
        selectedItem () {
            return this.$store.state.selectedItem
        },
        selectedColumns () {
            return this.$store.state.selectedColumns
        },
        profileData() {
            let profile = JSON.parse(window.sessionStorage.getItem('data-profile-jaskipin-agen'));
            if(profile) {
                return profile;
            }else{
                return null;
            }
        }
    },
    mounted() {
        /* Print Page */
        setTimeout(function() {
            window.print();
        }, 2000);
    },
    methods: {
        formatDate(value) {
            let options = { year: 'numeric', month: 'long', day: 'numeric' };
            let date = new Date(value).toLocaleString('id-ID', options);
            return date;
        },
    }
}
</script>

<style scoped>
@page {	
    size: A4;
    margin: 0;
}
</style>